import { makeAutoObservable } from 'mobx'

class RootStoreKeyowrdTracker {
    constructor() {
        console.log('RootStore constructed: ', this.selectedKeywordGroup)
        makeAutoObservable(this)
    }

    keywords = [];

    keywordGroups = [];

    getKeywords() {
        return this.keywords;
    }

    setKeywords(keywords) {
        if (keywords.length > 0) {
            this.keywords = keywords;
        } else {
            this.keywords = [];
        }
    }

    setKeywordGroups(keywords) {
        if (keywords.length > 0) {
            this.keywordGroups = [...new Map(keywords.map(item => [item.keyword_group_name, { group_id: item.group_id, keyword_group_name: item.keyword_group_name }])).values()];
        } else {
            this.keywordGroups = [];
        }
    }

    selectedKeywordGroup = {};

    setSelectedKeywordGroup(keywordGroup) {
        this.selectedKeywordGroup = keywordGroup;
    }
}

const rootStoreKeywordTracker = new RootStoreKeyowrdTracker()
export default rootStoreKeywordTracker;