import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import rootStoreKeywordTracker from '../../store/rootStoreKeywordTracker';
import { observer } from 'mobx-react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default observer(function DropSelect() {
  const [keywordGroup, setKeywordGroup] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const selectedObject = rootStoreKeywordTracker.keywordGroups.find(
      (group) => group.group_id === value
    );

    setKeywordGroup(selectedObject ? [selectedObject.group_id] : []);
    if (selectedObject) {
      rootStoreKeywordTracker.setSelectedKeywordGroup(selectedObject); // Pass the whole object
    }
  };

  return (
    <Box sx={{
      width: '100%',
    }}>
      <FormControl
        size="small"
        sx={{
          minWidth: 280,
          width: '100%',
          // media
          '@media (max-width: 600px)': {
            minWidth: '100%',
            width: '100%',
          },
        }}>
        <Select
          fullWidth
          displayEmpty
          value={keywordGroup}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <em>
                  Select a keyword group
                </em>
              );
            }

            return selected;
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {rootStoreKeywordTracker.keywordGroups.map((group, index) => (
            <MenuItem
              key={index}
              value={group.group_id}
            >
              {group.keyword_group_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
});
