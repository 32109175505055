import rootStore from '../../store/rootStore';
import { Box, Container } from '@mui/material';
import HeaderBar from '../HeaderBar/HeaderBar';
import { useNavigate } from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();

    if (rootStore.currentUser.accessToken === "") {
        navigate("/login");
        return
    }

    return (
        <Box sx={{ marginTop: '64px' }}>
            <HeaderBar />
            <Container
                maxWidth={false}
                sx={{
                    paddingY: 1,
                    marginLeft: rootStore.isDrawerOpen ? '250px' : 'auto',
                    maxWidth: rootStore.isDrawerOpen ? 'calc(100% - 250px)' : '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transition: '0.2s ease-in-out',
                    // media
                    '@media (max-width: 600px)': {
                        marginLeft: 'auto', // Set marginLeft to 'auto' for mobile screens
                        width: '100%',
                        alignItems: 'stretch',
                    },
                }}
            >
                {children}
            </Container>
        </Box>
    )
}