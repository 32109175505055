import React from "react";
import { Routes, Route, redirect, Navigate } from 'react-router-dom';
import PageNotFound from '../../pages/PageNotFound/PageNotFound';
import Welcome from '../../pages/Welcome/Welcome';
import Competitors from '../../pages/Competitors/Competitors';
import Analytics from '../../pages/Analytics/Analytics';
import Login from '../../pages/Login/LoginPage';
import Register from '../../pages/Register/Register';
import KeywordTracker from '../../pages/KeywordTracker/KeywordTracker';
import StyleGuide from '../../pages/StyleGuide/StyleGuide';
import Reports from '../../pages/Reports/Reports';
import { ProtectedRoute } from './ProtectedRoute';


export const NavRouter = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={
                <ProtectedRoute>
                    <PageNotFound />
                </ProtectedRoute>}>
            </Route>
            <Route path="/" element={
                <ProtectedRoute>
                    <Welcome />
                </ProtectedRoute>}>
            </Route>
            <Route path="/keyword-tracker" element={
                <ProtectedRoute>
                    <KeywordTracker />
                </ProtectedRoute>}>
            </Route>
            <Route path="/competitors" element={
                <ProtectedRoute>
                    <Competitors />
                </ProtectedRoute>}>
            </Route>
            <Route path="/analytics" element={
                <ProtectedRoute>
                    <Analytics />
                </ProtectedRoute>}>
            </Route>
            <Route path="/style-guide" element={
                <ProtectedRoute>
                    <StyleGuide />
                </ProtectedRoute>}>
            </Route>
            <Route path="/reports" element={
                <ProtectedRoute>
                    <Reports />
                </ProtectedRoute>}>
            </Route>
        </Routes>
    )
}