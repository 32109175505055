import rootStore from "../store/rootStore";

export const handlePost = async (url, data) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    });

    return await response.json();

  } catch (error) {
    console.log("Error auth/jwt: ", error);
  }
}

export const handleGet = async (url) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    return await response.json();

  } catch (error) {
    console.log("Error auth/jwt: ", error);
  }
}

export const handlePostJwt = async (url, data, accessToken) => {

  try {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: formData,
    });

    // Check for successful response
    if (!response.ok) {
      if (response.status === 401 && response.statusText === "Unauthorized") {
        rootStore.unsetCurrentUser();
        rootStore.resetLoginErrors();
        rootStore.setLoginError({
          messages: {
            error: "Your session has expired, please login."
          }
        });
      }
    }

    return await response.json();
  } catch (error) {
    console.log("Error: ", error);
  }
}

export const handleGetJwt = async (url, accessToken) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
    });

    const res = await response.json();
    console.log('jwt get res -', res);
    // return await response.json();

  } catch (error) {
    console.log("Error auth/jwt: ", error);
  }
}

