import './App.css';
// import { Routes, Route, redirect, Navigate } from 'react-router-dom';
// import Login from './pages/Login/LoginPage';
// import Register from './pages/Register/Register';
// import KeywordTracker from './pages/KeywordTracker/KeywordTracker';
import { ThemeProvider } from '@mui/material/styles';
import theme from './constants/theme';
// import StyleGuide from './pages/StyleGuide/StyleGuide';
// import { Box, Container } from '@mui/material';
// import HeaderBar from './components/HeaderBar/HeaderBar';
// import Welcome from './pages/Welcome/Welcome';
// import Competitors from './pages/Competitors/Competitors';
// import Analytics from './pages/Analytics/Analytics';
// import PageNotFound from './pages/PageNotFound/PageNotFound';
// import Reports from './pages/Reports/Reports';
import rootStore from './store/rootStore';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavRouter } from './components/NavRouter/NavRouter';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location.pathname);

  useEffect(() => {
    console.log("token: ", rootStore.currentUser.accessToken, location.pathname);
    setCurrentLocation(location.pathname);

    if (rootStore.currentUser.accessToken === ""
      && (currentLocation !== '/login' || currentLocation !== '/register')) {
      navigate("/login");
      return
    }

  }, [rootStore.currentUser.accessToken]);

  return (
    <ThemeProvider theme={theme}>
      <NavRouter />
    </ThemeProvider>
  )
}

export default observer(App);
