import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import svg from './404.svg';

function PageNotFound() {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    return (
        <Container component="main" maxWidth="xs">
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '100vh', textAlign: 'center' }}
            >
                <Grid item xs={3}>
                    <img src={svg} alt="Page not found" style={{ width: '100%' }} />
                    <p>Nothing to see here, <a onClick={goBack} href="#">go back.</a></p>
                </Grid>
            </Grid>
        </Container>
    );
}

export default observer(PageNotFound);